import React from "react";
import '../styles/semantic.min.css';
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import PromoVideoFull from "../components/promo-video/PromoVideoFull";
import MobileTabletContainer from "../container/MobileTabletContainer";
import DesktopContainer from "../container/DesktopContainer";
import layout from "../templates/layout.module.css";

const Video = () => (
    <LayoutContainer>
        <SEO title={"Video"}/>
        <MobileTabletContainer>
            <MainNavigation/>
            <>
                <PromoVideoFull/>
            </>
            <Footer/>
        </MobileTabletContainer>
        <DesktopContainer>
            <div className={layout.pageWrapper}>
                <MainNavigation/>
                <>
                    <PromoVideoFull/>
                </>
                <Footer/>
            </div>
        </DesktopContainer>

    </LayoutContainer>
);


export default Video;
