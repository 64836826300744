import React, {Component} from 'react';
import video1 from "./assets/promo-web.mp4";
import video2 from "./assets/promo-web.webm";
import videoLow1 from "./assets/promo-web-low.mp4";
import videoLow2 from "./assets/promo-web-low.webm";
import styles from "./video.module.css";
import thumbnail from "../../assets/snap2.jpg";
import DesktopContainer from "../../container/DesktopContainer";
import {BigPlayButton, ControlBar, CurrentTimeDisplay, Player, TimeDivider, VolumeMenuButton} from 'video-react';
import "./styles/video-react.css";
import TabletContainer from "../../container/TabletContainer";
import MobileContainer from "../../container/MobileContainer";
import YouTube from 'react-youtube';

class PromoVideoFull extends Component {
    render() {
        const opts = {
            height: '180',
            width: '320',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 1
            }
        };
        return (
            <>
                <div className={styles.videoBackground}>
                    <div className={styles.videoForeGround}>
                        <MobileContainer>
                            <YouTube
                                className={styles.video}
                                videoId="4RTqKi5SNOM"
                                opts={opts}
                                onReady={this._onReady}
                            />
                        </MobileContainer>
                        <TabletContainer>
                            <Player poster={thumbnail}
                                    className={styles.video}
                                    fluid={false}
                                    width={640}
                                    height={360}
                                    playsInline
                            >
                                <source src={videoLow1}/>
                                <source src={videoLow2}/>
                                <BigPlayButton position="center"/>
                                <ControlBar>
                                    <CurrentTimeDisplay order={4.1}/>
                                    <TimeDivider order={4.2}/>
                                    <VolumeMenuButton/>
                                </ControlBar>
                            </Player>
                        </TabletContainer>
                        <DesktopContainer>
                            <Player poster={thumbnail}
                                    className={styles.video}
                                    fluid={false}
                                    width={848}
                                    height={480}
                                    playsInline={true}
                            >
                                <source src={video1}/>
                                <source src={video2}/>
                                <BigPlayButton position="center"/>

                                <ControlBar>
                                    <CurrentTimeDisplay order={4.1}/>
                                    <TimeDivider order={4.2}/>
                                    <VolumeMenuButton/>
                                </ControlBar>
                            </Player>
                        </DesktopContainer>

                    </div>
                </div>
            </>
        );
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
}


export default PromoVideoFull;